/* eslint-disable react/display-name */
import { MDXProvider } from '@mdx-js/react';
import { HelmetDatoCms } from '@rasahq/gatsby-source-datocms';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import styled from 'styled-components';

import { Box } from 'src/components/atoms/Box';
import { Button } from 'src/components/atoms/Button';
import { Column, Grid } from 'src/components/molecules/Grid';
import { Quote } from 'src/components/molecules/Quote';
import { Section } from 'src/components/molecules/Section';
import { Hero } from 'src/components/organisms/Hero';
import { Link } from 'src/containers/Link';
import { Device } from 'src/embeds/device/Device';
import { FixedImg } from 'src/embeds/image/image-dato';

export default function Page({ data: { datoCmsCaseStudy: page } }) {
  const { heading, subHeading, contentNode, mdxData, customer, drawers, demoMedia, demoFrame, blocks } = page;
  const customerStats = customer?.stats ? JSON.parse(customer.stats) : null;

  return (
    <>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Hero
        primary
        title={heading}
        subtitle={subHeading}
        centered
        wavey
        image={!!demoMedia && <Device data={demoMedia} border={demoFrame} />}
        actions={<Button to="/product/rasa-platform/">Explore Rasa</Button>}
      />
      <Section maxWidth="large" centered>
        <Grid columns="2fr 1fr">
          <Column>
            <MDXProvider
              components={{
                a: ({ href, ...props }) => <Link to={href} {...props} />,
                Button,
                Quote: ({ dataKey }) => {
                  const data = mdxData?.find((data) => data.dataKey === dataKey);
                  if (!data) return null;

                  return (
                    <Quote
                      image={
                        !!data.image && (
                          <QuoteImageContainer>
                            <FixedImg width={56} height={56} data={data.image} />
                          </QuoteImageContainer>
                        )
                      }
                      source={data.cite}
                      contentProps={{
                        dangerouslySetInnerHTML: { __html: data.textNode.childMarkdownRemark.html },
                      }}
                    />
                  );
                },
              }}
            >
              <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
            </MDXProvider>
            <hr />
          </Column>

          <Column>
            {!!customer && (
              <Box>
                {!!customer.logo && (
                  <FixedImg
                    constrain
                    width={Math.round((customer.logo.width / customer.logo.height) * 30)}
                    height={30}
                    data={customer.logo}
                  />
                )}
                <br />

                {customerStats && (
                  <>
                    {!!customerStats.industry && <p>Industry: {customerStats.industry}</p>}
                    {!!customerStats.location && <p>Location: {customerStats.location}</p>}
                    {!!customerStats.employees && <p>Employees: {customerStats.employees}</p>}
                    {!!customerStats.revenue && <p>Revenue: {customerStats.revenue}</p>}
                  </>
                )}
              </Box>
            )}
          </Column>
        </Grid>

        {blocks.map(({ id, text, href }) => (
          <Button key={id} large tertiary to={href}>
            {text}
          </Button>
        ))}
      </Section>
      {drawers?.map((section, i) => {
        const Section = section.moduleComponent.component;
        return Section ? <Section key={i} {...section} /> : null;
      })}
    </>
  );
}

export const query = graphql`
  query ($id: String) {
    datoCmsCaseStudy(id: { eq: $id }) {
      ...GatsbyDatoCmsCaseStudyData
    }
  }
`;

const QuoteImageContainer = styled.div`
  border-radius: 100%;
  overflow: hidden;
`;
