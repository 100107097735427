import styled, { css } from 'styled-components';
import React from 'react';
import { FluidImg } from '../image/image-dato';
import SquiggleData from 'src/images/device/squiggle.svg';
import PatternData from 'src/images/device/pattern.svg';
import ButtonsLeftData from 'src/images/device/buttons-left.svg';
import ButtonRightData from 'src/images/device/button-right.svg';
export function Device({ data, border = 'none', style }) {
  return (
    <Container border={border} style={style}>
      <Placeholder
        width={data.width}
        height={data.height}
        viewBox={`0 0 ${data.width} ${data.height}`}
        xmlns="http://www.w3.org/2000/svg"
      />
      {border !== 'none' && (
        <>
          <Pattern src={PatternData} />
          <Squiggle src={SquiggleData} />
        </>
      )}

      {border === 'device' && (
        <>
          <ButtonsLeft src={ButtonsLeftData} />
          <ButtonRight src={ButtonRightData} />
        </>
      )}

      {data.isImage ? (
        /** @ts-ignore */
        <Image data={data} />
      ) : (
        <Video width={data.width} height={data.height} autoPlay muted playsInline controls loop>
          <source src={data.video.mp4Url} type="video/mp4" />
        </Video>
      )}
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: fit-content;
  max-width: 500px;
  max-height: 500px;
  border-radius: 15px;
  background-color: white;

  ${({ border }) =>
    !!border &&
    css`
      box-shadow: 0 0 0 3px #908ffa, 0 0 0 12px #dddbf8;
    `}
`;
const Placeholder = styled.svg`
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: inherit;
`;
const Video = styled.video`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  display: block;
  object-fit: contain;
  border-radius: 15px;
  overflow: hidden;
`;
const Image = styled(FluidImg)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  display: block;
  object-fit: contain;
  border-radius: 15px;
  overflow: hidden;
`;
const Squiggle = styled.img`
  position: absolute;
  bottom: 20%;
  width: 20%;
  left: -30%;
  z-index: -1;
`;
const Pattern = styled.img`
  position: absolute;
  top: -8%;
  right: -18%;
  width: 35%;
  z-index: -1;
`;
const ButtonsLeft = styled.img`
  position: absolute;
  top: 12%;
  left: -18px;
  z-index: -1;
`;
const ButtonRight = styled.img`
  position: absolute;
  top: 25%;
  right: -20px;
  z-index: -1;
`;
