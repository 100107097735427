import React from 'react';
import styled from 'styled-components';
const Container = styled.blockquote`
  margin: 0 0 1.5rem;
  font-size: 1rem;
  line-height: 1.75;
`;
const ARROW_SIZE = 28;
const Content = styled.div`
  position: relative;
  padding: 2rem 1.5rem;
  border: 1px solid #e3e3e3;
  border-radius: 0.75rem;
  background-color: white;
  margin-bottom: 1.75rem;

  &:before {
    content: '';
    position: absolute;
    top: calc(100%);
    left: 4rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${ARROW_SIZE}px ${ARROW_SIZE}px 0 ${ARROW_SIZE}px;
    border-color: #e3e3e3 transparent transparent transparent;
  }

  &:after {
    content: '';
    position: absolute;
    top: calc(100%);
    left: calc(4rem + 2px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${ARROW_SIZE - 2}px ${ARROW_SIZE - 2}px 0 ${ARROW_SIZE - 2}px;
    border-color: white transparent transparent transparent;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;
const Source = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1.75rem;
`;
const SourceImage = styled.div`
  line-height: 1;

  > *:last-child {
    margin-bottom: 0;
  }
`;
const SourceTitle = styled.cite`
  position: relative;
  font-weight: 500;
  font-style: normal;
  padding-left: 1rem;
`;
export const Quote = ({ image, source, children, contentProps }) => (
  <Container>
    <Content {...contentProps}>{children}</Content>
    <Source>
      {!!image && <SourceImage>{image}</SourceImage>}
      {!!source && <SourceTitle>{source}</SourceTitle>}
    </Source>
  </Container>
);
